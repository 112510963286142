import { Component, OnInit } from '@angular/core';
import { SharedService } from '../services/shared.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  cashPaymentPrintEmitter: any;
  receipt
  constructor(
    public sharedService: SharedService
  ) { }

  ngOnInit() {
    this.cashPaymentPrintEmitter = this.sharedService.cashPaymentPrintEmitter.subscribe(val => {
      this.receipt = JSON.parse(JSON.stringify(val));
      setTimeout(() => {
        window.print()
      }, 500)
    })
  }

  getRefreshTime(expTime) {
    var t1 = new Date().getTime() / 1000
    var dif = t1 - expTime;
    return 1000 * (dif * (-1) - 5);
  }
}