import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { SharedService } from "./shared.service";
import { catchError, tap, map } from "rxjs/operators";
import { isObject } from "util";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable({
  providedIn: "root",
})
export class BaseService {
  constructor(public http: HttpClient, public sharedService: SharedService) {}

  getList(resourceUrl: string): Observable<any> {
    return this.http.get(this.sharedService.apiUrl(resourceUrl)).pipe(
      map((res) => {
        return res;
      })
    );
  }



  getDictionaryList(resourceUrl: string): Observable<any> {
    return this.http.get(this.sharedService.apiUrl(resourceUrl)).pipe(
      map((res: any[]) => {
        return res.map(elem => ({ name: elem.name, id: elem.id }));
      })
    );
  }

  public getTableData(resourceUrl:string,
    filter = '', sortOrder = 'asc', sortColumn = '',
    pageNumber = 0, pageSize = 10, objectFilter): Observable<any> {

    var objFilter = ''
    for (var key in objectFilter){
      objFilter += "&"+key+'='+objectFilter[key]
    }

    return this.http.get(this.sharedService.apiUrl(resourceUrl)+'?q=' + filter +
    '&from=' + pageNumber +
    '&size=' + pageSize +
    objFilter + 
    '&sort=' +sortColumn +':' +
    (sortOrder==='desc'?'-1':'1')).pipe(
      map(res => {
        return res;
      })
    );
  }

  getResource(resourceUrl: string, id: any): Observable<any> {
    return this.http
      .get(this.sharedService.apiUrl(resourceUrl + "/" + id))
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  updateResource(resourceUrl: string, resource: any): Observable<any> {
    if (resource.id) {
      return this.http
        .put<any>(
          this.sharedService.apiUrl(resourceUrl + "/" + resource.id),
          JSON.stringify(resource),
          httpOptions
        )
        .pipe(
          tap((resource: any) =>
            this.log(`updated ${resourceUrl} w/ id=${resource.id}`)
          ),
          catchError(this.handleError<any>("update"))
        );
    } else {
      return this.http
        .post<any>(
          this.sharedService.apiUrl(resourceUrl),
          resource,
          httpOptions
        )
        .pipe(
          tap((resource: any) =>
            this.log(`added ${resourceUrl} w/ id=${resource.id}`)
          ),
          catchError(this.handleError<any>("add"))
        );
    }
  }

  patchResource(resourceUrl: string, resource: any): Observable<any> {
    return this.http
      .patch<any>(
        this.sharedService.apiUrl(resourceUrl + "/" + resource.id),
        resource,
        httpOptions
      )
      .pipe(
        tap((resource: any) =>
          this.log(`activated ${resourceUrl} w/ id=${resource.id}`)
        ),
        catchError(this.handleError<any>("activate"))
      );
  }
  deleteResource(resourceUrl: string, resource: any): Observable<any> {
    return this.http
      .delete<any>(
        this.sharedService.apiUrl(resourceUrl + "/" + resource.id),
        resource
      )
      .pipe(
        tap((resource: any) =>
          this.log(`updated ${resourceUrl} w/ id=${resource}`)
        ),
        catchError(this.handleError<any>("update"))
      );
  }

 

  deactivateResource(resourceUrl: string, resource: any): Observable<any> {
    return this.http
      .delete<any>(this.sharedService.apiUrl(resourceUrl + "/" + resource.id))
      .pipe(
        tap((resource: any) =>
          this.log(`deactivated ${resourceUrl} w/ id=${resource.id}`)
        ),
        catchError(this.handleError<any>("deactivate"))
      );
  }


  assignCDIS(resource: any): Observable<any> {
    return this.http
      .put<any>(
        this.sharedService.apiUrl("user/assigncdis/" + resource.id),
        JSON.stringify(resource),
        httpOptions
      )
      .pipe(
        tap((resource: any) => this.log(`updated user w/ id=${resource.id}`)),
        catchError(this.handleError<any>("update"))
      );
  }

  public handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  public log(message: string) {
    //this.messageService.add('HeroService: ' + message);
  }
  isObject(value) {
    return value && typeof value === "object" && value.constructor === Object;
  }
}
