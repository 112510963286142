// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
  production: true,
  apihost: 'https://ivory-coast-tas-server.revenuedev.org/web',
  apihostDomain : 'https://ivory-coast-tas-server.revenuedev.org',
  authServerhost: 'https://sso.revenuedev.org/?returnUrl=https://ivory-coast-govin.revenuedev.org',
  authServerhostLogout: 'https://sso.revenuedev.org/logout?returnUrl=https://ivory-coast-govin.revenuedev.org',
  whitelistDomain: "ivory-coast-tas-server.revenuedev.org",
  blacklistDomain: "ivory-coast-tas-server.revenuedev.org/web/auth",
  immatriculationWeb: "https://ivory-coast-immatriculation.revenuedev.org",
  boundary: [ -4.171319007999955, 5.227361203000044, -3.810719012999925, 5.476334095000084 ],
  default_lang: 'fr'
};

