import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ProcessService } from "src/app/services/process.service";
import { SharedService } from "src/app/services/shared.service";
import { TaxPayerService } from "src/app/services/taxPayer.service";
import { UserService } from "src/app/services/user.service";
@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
  taxPayer
  videoUrl
  activeProcesses
  uniqueProcesses
  constructor( private taxPayerService: TaxPayerService, private router: Router, private sharedService: SharedService, private processService: ProcessService) {}

  async ngOnInit() {
    this.taxPayer = await this.taxPayerService.getTaxPayer('tax-payers').toPromise()
    this.videoUrl = await this.sharedService.getVideoConfig().toPromise();
    this.activeProcesses = await this.processService.getActiveProcesses().toPromise();
    this.uniqueProcesses = this.activeProcesses.filter(
      (process, index, self) =>
        index === self.findIndex((p) => p.name === process.name)
    );
  
  }

  goToTaxLedger(){
    this.router.navigate(['taxledger'])
  }

  goToDeclaration(){
    this.router.navigate(['declaration'])
  }
}
