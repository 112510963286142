import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { BaseService } from "./base.service";
import { SharedService } from "./shared.service";
const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable({
  providedIn: "root",
})
export class TaxPayerService extends BaseService {
  sendSMS(data) {
    return this.http
      .post<any>(
        this.sharedService.apiUrl("tax-payers/sendSMS/payment"), data, httpOptions)
      .pipe(
        tap((request: any) => super.log(`added request w/ id=${request}`)),
        catchError(this.handleError<any>("add"))
      );
  }
  constructor(public http: HttpClient, public sharedService: SharedService) {
    super(http, sharedService);
  }
  getTaxPayer(resourceUrl: string): Observable<any> {
    return this.http
      .get(this.sharedService.apiUrl(resourceUrl + "/niu"))
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
}
