import { TaxPayerService } from 'src/app/services/taxPayer.service';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { TranslateService } from "@ngx-translate/core";
import { ChartDataSets } from "chart.js";
import { Label } from "ng2-charts";
import { fromEvent, merge } from "rxjs";
import { debounceTime, distinctUntilChanged, tap } from "rxjs/operators";
import { AvisService } from "src/app/services/avis.service";
import { FinancesDataSource } from "src/app/services/finances.datasource";
import { SharedService } from "src/app/services/shared.service";
import { UserService } from "src/app/services/user.service";
import { YearService } from "src/app/services/year.service";
import { SendSmsDialogComponent } from "../dialogs/send-sms-dialog/send-sms-dialog.component";
import { ConfirmationDialogComponent } from "../dialogs/confirmation-dialog/confirmation-dialog.component";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Process } from 'src/app/entities/process';
import { ProcessService } from 'src/app/services/process.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { saveAs } from 'file-saver';
import { jsPDF } from "jspdf";
import { ExternalService } from 'src/app/services/eternal.service';
import html2canvas from "html2canvas";


@Component({
  selector: "app-tax-ledger",
  templateUrl: "./tax-ledger.component.html",
  styleUrls: ["./tax-ledger.component.css"],
})
export class TaxLedgerComponent implements OnInit, OnDestroy {
  @Input()
  currentYear: any;
  @Input()
  currentNIU: any;
  currentYearInt: any;
  displayedYears = [];
  dataSource: FinancesDataSource;
  chartDataSubscription: any
  refreshDataSubscription: any
  displayedColumns: string[] = ['date', 'paymentSystem', 'paymentReference', 'rec_num', 'debit', 'credit', 'print'];
  @Input() company: any;
  avis;
  otpPayment = 0;
  avisMapping = {};
  finances = [];
  barChartLabels: Label[];
  barChartData: ChartDataSets[];
  filterObject: any;
  user: any;
  loading = { inProgress: true }
  selectedButton 
  process: Process;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('input_search', { static: false }) input: ElementRef;
  canSendSms = false
  subscriptionDeletePayment: any
  constructor(
    public taxPayerService: TaxPayerService,
    public jwtHelper: JwtHelperService,
    private yearService: YearService,
    private avisService: AvisService,
    public translate: TranslateService,
    public dialog: MatDialog,
    private sharedService: SharedService,
    public userService: UserService,
    public processService: ProcessService,
    private spinner: NgxSpinnerService,
    private externalService: ExternalService
  ) { }
      
  async ngOnInit() {
    this.company = await this.taxPayerService.getTaxPayer('tax-payers').toPromise()
    if (this.company && this.company.type.avis)
    this.displayedColumns = ['date', 'paymentSystem', 'paymentReference', 'rec_num', 'debit', 'credit', 'balance', 'print'];
    this.yearService.getList("year").subscribe((val) => {
      val.forEach((element) => {
        this.displayedYears.push(element.year);
      });
      if (val && val.length > 0) {
        this.currentYear = val[0].year;
        this.currentYearInt = val[0].year * 1;
      }
    });

    this.dataSource = new FinancesDataSource(this.avisService, this.translate, 'ledger');
    this.chartDataSubscription = this.avisService.chartDataEmitter.subscribe(val => {
      this.barChartData = val.data
      this.barChartLabels = val.labels
    })
    this.refreshDataSubscription = this.sharedService.cashPaymentPrintEmitter.subscribe(async val => {
      await this.refreshData()
    })
  }

  async refreshData(){
    this.dataSource = new FinancesDataSource(this.avisService, this.translate, 'ledger');
    this.loadFinancesTable()
  }

  handleSelection(event) {
    this.loading.inProgress = true
    this.currentYear = event.value;
    this.dataSource.loadFinances(
      this.currentYear, 
      '', 
      'asc', 
      'date', 
      0, 
      this.paginator.pageSize, 
      this.pType, 
      this.filterObject, 
      this.company.extraBalance,
      this.loading
    );
  }

  downloadReceiptPdf(p) {
    let headers = {
      title: this.translate.instant("RECEIPT.ELECTRONIC_PAYMENT"),
      company: this.translate.instant("RECEIPT.COMPANY_NAME"),
      tin: this.translate.instant("RECEIPT.TIN"),
      monthYear: this.translate.instant("RECEIPT.MONTH_YEAR"),
      referenceNumber: this.translate.instant("RECEIPT.REFERENCE_NUMBER"),
      processName: this.translate.instant("RECEIPT.PROCESS_NAME"),
      telephone: this.translate.instant("RECEIPT.TELEPHONE"),
      taxName: this.translate.instant("RECEIPT.TAX_NAME"),
      amount: this.translate.instant("RECEIPT.AMOUNT"),
      collector: this.translate.instant("RECEIPT.COLLECTOR"),
      serialNumber: this.translate.instant("RECEIPT.RECEIPT_CODE"),
      thanksText: this.translate.instant("RECEIPT.THANKS"),
      dateTime: this.translate.instant("RECEIPT.DATE"),
    }
    this.loading.inProgress = true;
    this.externalService.downloadReceiptPdf(p.urlident, headers).subscribe
      (data => {
        this.loading.inProgress = false;
        const blob = data;
        const file = new Blob([blob], {});
        const filename = 'document-' + Date.now() + '.pdf';
        saveAs(file, filename);
      });
  }


  saveAndSendSMS(p): void {
    let cashPayment = { reference_number: p.reference_number, onlySms: true, telephone: this.company.telephone || this.company.additionalFields.telephone }
    const dialogRef = this.dialog.open(SendSmsDialogComponent, {
      width: "500px",
      panelClass: "custom-dialog-container",
      data: { cashPayment: cashPayment, paymentSaved: false },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.sent) {
        this.loadFinancesTable()
      }
    });
  }

  deletePayment(p) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { text: "DIALOG.DELETE_AVIS_PAYMENT", emitter: null, obj: p, action: "CONFIRM" }
    });

    this.subscriptionDeletePayment = this.sharedService.confirmDialogEmitter.subscribe(async val => {
      if (val) {
        try {
          // await this.taxPayerService.deletePayment(val.reference_number).toPromise()
          await this.refreshData()
        } catch (error) {
          console.log('error')
        }
      }
    })

    dialogRef.afterClosed().subscribe(result => {
      this.subscriptionDeletePayment.unsubscribe()
    });
  }



  ngAfterViewInit() {
    setTimeout(() => {
      this.loading.inProgress = true
      this.dataSource.loadFinances(
        this.currentYear, 
        '', 
        'asc', 
        'date', 
        0, 
        this.paginator.pageSize, 
        this.pType, 
        this.filterObject, 
        this.company.extraBalance,
        this.loading
      );
    }, 1000)

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadFinancesTable();
        })
      )
      .subscribe();

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadFinancesTable())
      )
      .subscribe();

  }

  ngOnDestroy(): void {
    this.chartDataSubscription && this.chartDataSubscription.unsubscribe()
    this.refreshDataSubscription && this.refreshDataSubscription.unsubscribe()
  }
  loadFinancesTable() {
    this.loading.inProgress = true
    this.dataSource.loadFinances(
      this.currentYear, 
      this.input.nativeElement.value,
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize, 
      this.pType, 
      this.filterObject, 
      this.company.extraBalance,
      this.loading
    );
  }

  pType = "ALL"
  getFinances(val, type) {
    if (this.loading) delete this.loading['error']
    this.dataSource.emptyData()
    if (val.checked) {
      this.pType = this.pType === 'ALL' ? type : "ALL"
    } else {
      this.pType = this.pType !== 'ALL' ? 'ALL' : (type === 'FEES' ? "PAYMENTS" : "FEES")
    }
    this.loadFinancesTable()
  }
  getBackgroundClass(p: any): string {
    if (!p.paymentSystem || (p.paymentSystem && p.paymentSystem.name === 'Avis')) {
      return p.paid ? 'green-background' : 'red-background';
    }
    return '';
  }
  getRowClass(row: any): string {
    if (row.type === 'WALLET') {
      return 'light-green-background';
    }
    return '';
  }

  async downloadAvisFile(event, p) {
    event._elementRef.nativeElement.style.display = 'none';
    this.selectedButton = p.processId
    let avisData = await this.processService.getAvisData(p.processId).toPromise()
    const container = document.createElement("div");
    container.style.position = "absolute";
    container.style.left = "-9999px";
    container.style.width = "100%";
    container.innerHTML = avisData
    document.body.appendChild(container);
    html2canvas(container, { allowTaint: true, useCORS: true })
      .then((canvas) => {
        event._elementRef.nativeElement.style.display = 'block';
        this.selectedButton = undefined;
        document.body.removeChild(container);
        const imgData = canvas.toDataURL("image/jpeg", 0.7);
        const pdf = new jsPDF();
        const imgWidth = 190;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        pdf.addImage(imgData, "JPEG", 10, 10, imgWidth, imgHeight);
        pdf.save("avis-"+this.company.tin+".pdf");
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
      });
  }

  // downloadGeneratedFile(event,p){
  //   event._elementRef.nativeElement.style.display = 'none';
  //   this.selectedButton = p.processId
  //   this.processService.downloadGeneratedFile(p.processId,'avisfile').subscribe
  //     (data => {
  //       this.selectedButton = undefined;
        
  //       const blob = data;
  //       const file = new Blob([blob], {});
  //       const filename = 'document-' + Date.now() + '.pdf';
  //       saveAs(file, filename);
  //     });
  // }
  async goToPayment(p){
      try {
        const config = await this.sharedService.getPaymentConfig().toPromise();
        if (config && config.url) {
          const paymentUrl = `${config.url}?reference=${p.reference_number}`;
          window.open(paymentUrl, '_blank'); 
        } else {
          console.error("Payment URL is not defined in config.");
        }
      } catch (error) {
        console.error("Error fetching payment URL:", error);
      }
    }
  }
