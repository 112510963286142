import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';

@Component({
  selector: 'app-bar-chart-native',
  templateUrl: './bar-chart-native.component.html',
  styleUrls: ['./bar-chart-native.component.css']
})
export class BarChartNativeComponent implements OnInit {
  public barChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,

    scales: {
      yAxes: [{
        scaleLabel: {
          display: false,
          fontSize: 14,
          fontStyle: 'bold',
          fontColor: '#666',
        },
        ticks: {
          beginAtZero: true,
          callback: function (value, index, values) {
            return value.toLocaleString().replace(/\,/g, ' ')
          }
        }
      }],
      xAxes: [{
          gridLines: {
            lineWidth: 1,
          },
      }],
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        font: {
          size: 20,
        }
      }
    }
  };
  @Input() barChartLabels: Label[]
  public barChartType: ChartType = 'bar'
  @Input() barChartLegend = true
  public barChartPlugins = []
  @Input() barChartData: ChartDataSets[]
  @Input() title = ''
  @Input() yAxesTitle 
  @Input() cdiFilter = false
  @Input() yearFilter = false
  @Input() dsfFilter = false
  @Input() yearsFrom = false
  @Input() editable = true
  @Input() downloadable = false
  @Output() onSelectedValue = new EventEmitter<any>()
  emitValue = {
    cdi: '0',
    year: (new Date()).getFullYear(),
    dsf: '0'
  }
  constructor() { }
  ngOnInit() {
    if(this.yAxesTitle){
      this.barChartOptions['scales']['yAxes'][0]['scaleLabel']['labelString'] = this.yAxesTitle
      this.barChartOptions['scales']['yAxes'][0]['scaleLabel']['display'] = true
    }
    if (!this.editable) {
      this.barChartOptions['legend'] = {
      onClick: (e) => {
          e.stopPropagation()
        }
      }
    }
  }

  selectonChanged(value) {
    this.emitValue.cdi = value
    this.onSelectedValue.emit(this.emitValue)
  }

  yearSelectonChanged(value) {
    this.emitValue.year = value
    this.onSelectedValue.emit(this.emitValue)
  }

  @ViewChild('chart', {static:false}) chart: ElementRef;
  printChart() {
    var canvasDataUrl = this.chart.nativeElement.toDataURL()
      .replace(/^data:image\/[^;]*/, 'data:application/octet-stream');
    var link = document.createElement('a');
    link.setAttribute('href', canvasDataUrl);
    link.setAttribute('target', '_blank');
    link.setAttribute('download', this.title + ".png");
    if (document.createEvent) {
      var evtObj = document.createEvent('MouseEvents');
      evtObj.initEvent('click', true, true);
      link.dispatchEvent(evtObj);
    } else if (link.click) {
      link.click();
    }
  }
}
