import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SharedService } from './shared.service';
import { map } from 'rxjs/operators';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class AvisService extends BaseService {

  constructor(public http: HttpClient, public sharedService: SharedService) {
    super(http, sharedService)
  }

 getAvisByRefNum(refNum: string){
  return this.http.get(this.sharedService.apiUrl('process/avisbyrefnum')+refNum).pipe(
    map(res => {
      return res;
    })
  );
 }


 getPayments(dType) {
  const httpOptionsExcel = {
    headers: new HttpHeaders({ 'Accept': 'application/vnd.ms-excel', 'Content-Type': 'application/vnd.ms-excel' }),
    params: new HttpParams().set('dashboardFilter', this.stringifyWithUTC(dType))
  };
  let payments$ = this.http.get<any>(this.sharedService.apiUrl('fees/payments'), httpOptionsExcel)
  return payments$;
}

stringifyWithUTC(obj) {
  return JSON.stringify(obj, (key, value) => {
    if (obj && obj[key] instanceof Date) {
      const utcDateString = new Date(obj[key].getTime() - (obj[key].getTimezoneOffset() * 60000)).toISOString();
     return utcDateString
    }
    return value;
  });
}

getCompanyAvis(year, sortDirection, sortColumn, pageIndex, pageSize, pgOrig, filter, filterObject) {
  const httpOptionsExcel = {
    headers: new HttpHeaders({ 'Accept': 'application/vnd.ms-excel', 'Content-Type': 'application/vnd.ms-excel' }),
    params: new HttpParams().set('sortDirection', sortDirection).set('sortColumn', sortColumn).set('pageIndex', pageIndex).set('pageSize', pageSize).set('pgOrig', pgOrig).set('search', filter).set('filterObject', this.stringifyWithUTC(filterObject))
  };
  let avis$ = this.http.get<any>(this.sharedService.apiUrl('fees/year/' + year), httpOptionsExcel)
  return avis$;
}

getCompanyPayments( type, sortDirection, sortColumn, pageIndex, pageSize, pgOrig, filter, filterObject) {
  const httpOptionsExcel = {
    headers: new HttpHeaders({ 'Accept': 'application/vnd.ms-excel', 'Content-Type': 'application/vnd.ms-excel' }),
    params: new HttpParams().set('type', type).set('sortDirection', sortDirection).set('sortColumn', sortColumn).set('pageIndex', pageIndex).set('pageSize', pageSize).set('pgOrig', pgOrig).set('search', filter).set('filterObject', this.stringifyWithUTC(filterObject))
  };
  let payments$ = this.http.get<any>(this.sharedService.apiUrl('fees/payments'), httpOptionsExcel)
  return payments$;
}
@Output() chartDataEmitter: EventEmitter<any> = new EventEmitter();
 emitChartData(data) {
   this.chartDataEmitter.emit(data)
}
}
