import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { TranslateService } from "@ngx-translate/core";
import { ChartDataSets } from "chart.js";
import { Label } from "ng2-charts";
import { BehaviorSubject, combineLatest, Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { AvisService } from "./avis.service";



export class FinancesDataSource implements DataSource<any> {

  private financeSubject = new BehaviorSubject<any[]>([]);

  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();

  public size = 0;
  balance = 0;
  barChartLabels: Label[];
  barChartData: ChartDataSets[];
  pageSizeAvis = 0
  pageSizePayments = 0
  currentPage = [{ avis: 0, payments: 0, balance: undefined }]
  constructor(private avisService: AvisService, public translate: TranslateService, private type: String) {

  }
  prevBalance
  prevBalanceFirst
  globalPageIndex = 0
  chartEmitted = false


  async fetchDataWithAttempt(paymentType, year, type, filter, filterObject, sortDirection, sortColumn, pageIndex, pageSize, extraBalance, loading){
    let attempt = 0;
    let finances;
    while(attempt < 2){
      try {
        attempt++;
        const [avis = [], payments = []] = await this.getData(paymentType, year, type, filter, filterObject, sortDirection, sortColumn, pageIndex, this.pageSizeAvis, this.pageSizePayments, pageSize).pipe(
          catchError((error) =>  { 
            return of([{error}, {error}]) 
          })
        ).toPromise();
        if (avis.error && payments.error) 
          continue; 
        finances = [];
        if (extraBalance && this.type==='ledger') {
          finances.push({
            "type": "WALLET",
            "paymentSystem": {
              "key": "wallet",
              "name": "Wallet"
            },
            "currency": "XOF",
            "amount": extraBalance,
            "balance": extraBalance
          })
        }
        if (avis.records && typeof avis.records[Symbol.iterator] === 'function') {
          finances.push(...avis.records);
        }
        if (payments.records && typeof payments.records[Symbol.iterator] === 'function') {
          finances.push(...payments.records);
        }
        finances = finances.sort(this.sortByDate);
        finances = finances.slice(0, pageSize);
  
        if (this.currentPage[pageIndex]['balance'] === undefined) {
          this.currentPage[pageIndex]['balance'] = payments.total + (extraBalance && this.type==='ledger'?finances[0].amount:0) - (avis.total || 0);
        }
  
        this.pageSizeAvis += finances.filter(elem => !elem.paymentSystem).length
        this.pageSizePayments += finances.filter(elem => elem.paymentSystem).length
  
        finances.forEach((element, index) => {
          !element.paymentSystem && (element.amount = element.total)
          if (index === 0) {
            element.balance = this.currentPage[pageIndex]['balance']
          } else {
            element.balance = finances[index - 1].paymentSystem ? finances[index - 1].balance - finances[index - 1].amount : finances[index - 1].balance + finances[index - 1].amount
  
          }
          if (index === finances.length - 1) {
            this.currentPage[pageIndex + 1] = { avis: this.pageSizeAvis, payments: this.pageSizePayments, balance: element.paymentSystem ? element.balance - element.amount : element.balance + element.amount }
          }
  
        })
        this.globalPageIndex = pageIndex
        this.barChartData = [
          {
            data: [
              avis.records ? avis.total : 0
            ],
            label: this.translate.instant("TAX-LEDGER.TO-PAY"),
          },
          {
            data: [
              payments.records ? payments.total : 0
            ],
            label: this.translate.instant("TAX-LEDGER.PAID"),
          },
        ];
        this.barChartLabels = [year];
        !this.chartEmitted && this.avisService.emitChartData({ data: this.barChartData, labels: this.barChartLabels })
        this.chartEmitted = true
        this.size = (avis.count || 0) + (payments.count || 0) + ((extraBalance && this.type==='ledger') ? 1 : 0)
        this.financeSubject.next(finances)
        loading.inProgress = false

          break;
      } catch (error) {
        if(error.name !== 'TimeoutError' || attempt >= 2){
          console.error('Fetch Failed:', error);
          loading.inProgress = false
          break;
        }
      }
    }
    if (attempt === 2 && finances === undefined) {
      loading.inProgress = false
      loading.error = true
    }
  }

  async loadFinances(
    year: string,
    filter: any,
    sortDirection: string,
    sortColumn: string,
    pageIndex: number,
    pageSize: number, paymentType, filterObject:any, extraBalance, loading) {
    this.loadingSubject.next(true);

    if (this.currentPage[pageIndex]) {
      this.pageSizeAvis = this.currentPage[pageIndex]['avis']
      this.pageSizePayments = this.currentPage[pageIndex]['payments']
    }
    // await this.getData(paymentType, year, niu, this.type==='ledger'?'PAYMENTS':'WALLET', filter, filterObject, sortDirection, sortColumn, pageIndex, this.pageSizeAvis, this.pageSizePayments, pageSize)
    try {
      this.fetchDataWithAttempt(paymentType, year, this.type==='ledger'?'PAYMENTS':'WALLET', filter, filterObject, sortDirection, sortColumn, pageIndex,  pageSize, extraBalance, loading)
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      this.loadingSubject.next(false);
    }
    
    // this.getData(paymentType, year, niu, this.type==='ledger'?'PAYMENTS':'WALLET', filter, filterObject, sortDirection, sortColumn, pageIndex, this.pageSizeAvis, this.pageSizePayments, pageSize).pipe(
    //   catchError(() => of([])),
    //   finalize(() => this.loadingSubject.next(false))
    // ).subscribe(([avis = [], payments = []]) => {
 
    
    // })
  }

  loadChartData(type) {
    return this.avisService.getPayments(type);
  }

  getData(paymenType, year, type, filter, filterObject, sortDirection, sortColumn, pageIndex, pageSizeAvis, pageSizePayments, pgOrig): Observable<any[]> {
    const avisObservable = (type === 'WALLET' ? of([]) : this.avisService.getCompanyAvis(year, sortDirection, sortColumn, pageIndex, pageSizeAvis, pgOrig, filter, filterObject));
    const paymentsObservable = this.avisService.getCompanyPayments( type, sortDirection, sortColumn, pageIndex, pageSizePayments, pgOrig, filter, filterObject);

    return combineLatest([
      (paymenType === 'ALL' || paymenType === 'FEES') ? avisObservable : of([]),
      (paymenType === 'ALL' || paymenType !== 'FEES') ? paymentsObservable : of([])
    ]);
  }
  sortByDate(item1: any, item2: any): number {
    const date1 = new Date(item1.submission_date || item1.paymentDate);
    const date2 = new Date(item2.submission_date || item2.paymentDate);
    return date2.getTime() - date1.getTime();
  }

  setBalance(finances) {
    finances.forEach((elem) => {
      this.balance += elem.total * -1 || elem.amount;
      elem.balance = this.balance;
    });
  }
  emptyData() {
    this.financeSubject.next(null)
    this.size = 0;
}
  connect(collectionViewer: CollectionViewer): Observable<any[]> {
    return this.financeSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.financeSubject.complete();
    this.loadingSubject.complete();
  }


}