import { Injectable, Output, EventEmitter } from "@angular/core";
import { environment } from "../../environments/environment";
import { Observable, Subject } from "rxjs";
import { JwtHelperService } from "@auth0/angular-jwt";
import { User } from "../entities/user";
import { CompanyRepresentative } from "../entities/companyRepresentative";
import { HttpClient } from "@angular/common/http";
@Injectable()
export class SharedService {
  constructor(public jwtHelper: JwtHelperService, public http: HttpClient){

  }
  private lan$ = new Subject<any>();
  public workspace$ = new Subject<any>();
  private workspaceObject$ = new Subject<any>();

  apiUrl(pathToAPI: string, parameters = ''): string {
    return environment.apihost + '/' + pathToAPI + '/' + parameters;
  }

  public getPaymentConfig(): Observable<any> {
    return this.http.get(this.apiUrl('configs/payment'))
  }

  public getVideoConfig(): Observable<any> {
    return this.http.get(this.apiUrl('configs/video'))
  }

  apiUrlLogin(pathToAPI: string, parameters = ''): string {
    return environment.apihost + '/' + pathToAPI
  }

  authServerUrl(pathToAPI: string, parameters = ''): string {
    return environment.authServerhost;
  }

  authServerUrlLogout(): string {
    return environment.authServerhostLogout;
  }

  
  handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);
      return Observable.throw(error.error || 'Server error');
    };
  }



  log(message: string) {
    //this.messageService.add('HeroService: ' + message);
  }

  getLan$(): Observable<any> {
    return this.lan$.asObservable();
  }


  getLan() {
    return localStorage.getItem('default_lang');
  }

  getWorkspace$() {
    return this.workspace$.asObservable();
  }
  getWorkspaceObject$() {
    return this.workspaceObject$.asObservable();
  }

  setWorkspace(wokspace){
    localStorage.setItem('default_workspace', wokspace.tin);
    this.workspace$.next(wokspace.tin);
    this.workspaceObject$.next(wokspace);
  }

  workspaceRemoved(){
    this.workspace$.next(null)
  }


  setLan(lan) {
    localStorage.setItem('default_lang', lan);
    this.lan$.next(lan);
  }
  @Output() change: EventEmitter<boolean> = new EventEmitter();
  updateCompany(company) {
    this.change.emit(company);
  }
  @Output() updateToken: EventEmitter<boolean> = new EventEmitter();
  updateAccessToken(){
    this.updateToken.emit(true)
  }
  @Output() confirmDialogEmitter: EventEmitter<boolean> = new EventEmitter();
  confirmDialogAction(obj){
    this.confirmDialogEmitter.emit(obj)
  }

  @Output() confirmProceedDialogEmitter: EventEmitter<boolean> = new EventEmitter();
  confirmDialogActionProceed(obj){
    this.confirmProceedDialogEmitter.emit(obj)
  }

  @Output() cashPaymentPrintEmitter: EventEmitter<any> = new EventEmitter();
  cashPaymentPrint(payment) {
    this.cashPaymentPrintEmitter.emit(payment)
  }

  @Output() smsSendEmitter: EventEmitter<any> = new EventEmitter();
  smsSend(sms){
    this.smsSendEmitter.emit(sms)
  }

  @Output() deactivateUserEmmitter: EventEmitter<User> = new EventEmitter();

  deactivateEntity(obj) {
    if (obj instanceof User || obj['class'] === 'USER') {
      this.deactivateUserEmmitter.emit(obj)
    }

  }

  @Output() updateCompanyRepresentativeEmmitter: EventEmitter<User> = new EventEmitter();

  declineEntity(obj) {
    if (obj instanceof CompanyRepresentative || obj['class'] === 'COMPANY_REPRESENTATIVE') {
      obj['status'] = 2
      this.updateCompanyRepresentativeEmmitter.emit(obj)
    }
  }

  @Output() suspendCompRepEmmitter: EventEmitter<User> = new EventEmitter();

  suspendEntity(obj) {
    obj['status'] = 3
    if (obj instanceof CompanyRepresentative || obj['class'] === 'COMPANY_REPRESENTATIVE') {
      this.updateCompanyRepresentativeEmmitter.emit(obj)
    }

  }

  @Output() activateUserEmmitter: EventEmitter<User> = new EventEmitter();

  activateEntity(obj) {
    if (obj instanceof User || obj['class'] === 'USER') {
      this.activateUserEmmitter.emit(obj)
    }
    if (obj instanceof CompanyRepresentative || obj['class'] === 'COMPANY_REPRESENTATIVE') {
      obj['status'] = 1
      this.updateCompanyRepresentativeEmmitter.emit(obj)
    }
  }

  @Output() updateUserEmmitter: EventEmitter<User> = new EventEmitter();
  @Output() updateDropdownConfigurationEmmitter: EventEmitter<User> = new EventEmitter();

  updateEntity(obj) {
    if (obj instanceof User || obj['class'] === 'USER') {
      this.updateUserEmmitter.emit(obj)
    }
    if (obj instanceof CompanyRepresentative || obj['class'] === 'COMPANY_REPRESENTATIVE') {
      this.updateCompanyRepresentativeEmmitter.emit(obj)
    }
  }

  @Output() updateVisitEmmitter: EventEmitter<any> = new EventEmitter();
  updateVisit(obj) {
    this.updateVisitEmmitter.emit(obj)
  }

  formSavedEmmiter: EventEmitter<boolean> = new EventEmitter();
  saveForm(obj){
    this.formSavedEmmiter.emit(obj)
  }

  saveSubmitReportEmmiter: EventEmitter<boolean> = new EventEmitter();
  saveSubmitReport(){
    this.saveSubmitReportEmmiter.emit()
  }

  userProfileCreated: EventEmitter<boolean> = new EventEmitter();
  createdUserProfile(){
    this.userProfileCreated.emit()
  }

  tableChangeEmitter: EventEmitter<any> = new EventEmitter();
  changeTableEmit(obj){
    this.tableChangeEmitter.emit(obj)
  }
  userProfileEmitter: EventEmitter<any> = new EventEmitter();
  setUserProfile(obj){
    this.userProfileEmitter.emit(obj)
  }
  getUserFromToken() {
    return this.jwtHelper.decodeToken(localStorage.getItem('access_token')).username;
  }
}
