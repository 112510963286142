import { Company } from './company';
import { CompanyRepresentativeStatus } from './states/companyRepresentativeStatus';

export class CompanyRepresentative {
    public id: number;
    public company: Company;
    public firstname: string;
    public username: string;
    public lastname: string;
    public email: string;
    public status: CompanyRepresentativeStatus;
    public turnover: number;
    public currency: string;
    public address: string;
    public telephone: string;
    public type: any;
    public tin: string;
    public sector: string;
    public registrationNumber: string;
    public incorportionDate: Date;
    public birthDate: Date;
    public city: string;
    public country: string;
    public postalCode: string;
    public website: string;
    public description: string;
    public owner: string;
    public ownerCreation: boolean;
    public files: any[];
    public additionalFields = {};
    constructor() {

    }

  }
