import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { SharedService } from "./shared.service";
import { map, catchError, share } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class AuthService {
  constructor(private http: HttpClient, private sharedService: SharedService, private decoder: JwtHelperService) { }

  login(username: string, password: string): Observable<boolean> {
    return this.http.post<{ token: string }>(this.sharedService.apiUrlLogin('auth'), { username: username, password: password })
      .pipe(
        map(result => {
          if (result['statusCode'] === 200) {
            localStorage.setItem('access_token', result.token);
            return true;
          }
        }), catchError((err) => throwError(err))
      );
  }

  sso_login(token?): Observable<boolean> {
    let login$ = this.http.get<any>(this.sharedService.apiUrlLogin('sso_auth') + (token ? "/?rdToken=" + token : ""))
    return login$;
  }

  async requestToken(token) {
    let login$ = this.http.get<any>(this.sharedService.apiUrlLogin('sso_auth/renewToken/') + token)
    const res = await login$.toPromise().then(res => res);
    return res.usrToken;
  }

  async validateTasToken(token,username) {
    let login$ = this.http.get<any>(this.sharedService.apiUrlLogin('sso_auth/validateTasToken/') + token+'/'+username)
    const res = await login$.toPromise().then(res => res);
    return res;
  }

  async validateImmatriculationToken(token,username, tin) {
    let login$ = this.http.get<any>(this.sharedService.apiUrlLogin('sso_auth/validateImmatriculationToken/') + token+'/'+username+'/'+tin)
    const res = await login$.toPromise().then(res => res);
    return res;
  }

  requestTokenObs(token) {
    return this.http.get<any>(this.sharedService.apiUrlLogin('sso_auth/renewToken/') + token)
  }

  logout() {
    var accessToken = localStorage.getItem('access_token');
    return this.http.get<any>(this.sharedService.apiUrlLogin('logout') + (accessToken ? "/?localToken=" + accessToken : ""))
  }
  getTokenFromNonce(nonce): Observable<boolean> {
    let token$ = this.http.get<any>(this.sharedService.apiUrlLogin('sso_auth') + "/?nonce=" + nonce)
    return token$;
  }

  getRedirectLink(): Observable<boolean> {
    return this.http.get<any>(this.sharedService.apiUrlLogin('sso_auth')).pipe(catchError(this.handleError<any>('getRedirectLink')))
  }

  public get loggedIn(): boolean {
    return (localStorage.getItem('access_token') !== null);
  }



  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      this.log(`${operation} failed: ${error.message}`);
      if (result === undefined) {
        return throwError({ status: error.status });
      }
      return of(result as T);
    };
  }

  private log(message: string) {
    
  }


}
